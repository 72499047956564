import axios from "axios";
import { URL } from 'config/config';
import {
  setLoading,
  setError,
} from 'actions/common';
import {
  handleRequest,
} from 'utils/apiHelpers';
import {
  getSurveys,
  postSurvey,
  deleteSurveyById,
  editSurvey
} from 'apiActions/surveys';
import { showAlert } from "./alert";

export const SET_SURVEYS = 'SURVEY.SET_SURVEYS'
export const SAVE_SURVEY = 'SURVEY.SAVE_SURVEY'
export const DELETE_SURVEY_BY_ID = 'SURVEY.DELETE_SURVEY_BY_ID'
export const RESET_SURVEY_STATUS = 'SURVEY.RESET_SURVEY_STATUS'
export const FETCH_SURVEY_LIST = 'FETCH_SURVEY_LIST'
export const SET_SURVEY__LIST_LOADING = 'SET_SURVEY__LIST_LOADING'
export const FETCH_SURVEY_ANSWERS_LIST = 'FETCH_SURVEY_ANSWERS_LIST'
export const SET_SURVEY_TO_TEMPORARY_SERVICE = 'SET_SURVEY_TO_TEMPORARY_SERVICE'
export const SET_SURVEY_ANSWERS_LIST_LOADING = 'SET_SURVEY_ANSWERS_LIST_LOADING'
export const CLEAR_SURVEY_SET_TO_TEMPORARY_SERVICE = 'CLEAR_SURVEY_SET_TO_TEMPORARY_SERVICE'
export const FILTERED_FEEDBACK_ANSWERS_LIST = 'FILTERED_FEEDBACK_ANSWERS_LIST'
export const SET_FEEDBACK_ANSWERS_LIST_LOADING = 'SET_FEEDBACK_ANSWERS_LIST_LOADING'

const setSurveysByEventId = payload => ({
  type: FETCH_SURVEY_LIST,
  payload
});

const setSurveysLoading = payload => ({
  type: SET_SURVEY__LIST_LOADING,
  payload
});

const setSurveyAnswers = payload => ({
  type: FETCH_SURVEY_ANSWERS_LIST,
  payload
});

const setSurveyAnswersLoading = payload => ({
  type: SET_SURVEY_ANSWERS_LIST_LOADING,
  payload
});

const setFilteredFeedbackAnswers = payload => ({
  type: FILTERED_FEEDBACK_ANSWERS_LIST,
  payload
});
const setFeedbackAnswersLoading = payload => ({
  type: SET_FEEDBACK_ANSWERS_LIST_LOADING,
  payload
});

const setSurveys = payload => ({
  type: SET_SURVEYS,
  payload,
});

const saveSurvey = payload => ({
  type: SAVE_SURVEY,
  payload,
});

const deleteSurvey = payload => ({
  type: DELETE_SURVEY_BY_ID,
  payload,
});

export const resetSurveyStatus = () => ({
  type: RESET_SURVEY_STATUS,
});

export const setSurveyToTemporaryService = (payload) => ({
  type: SET_SURVEY_TO_TEMPORARY_SERVICE,
  payload,
})

export function clearBookingSurvey() {
  return {
    type: CLEAR_SURVEY_SET_TO_TEMPORARY_SERVICE,
  }
}

export const setSurveysAction = () => async dispatch => {
  dispatch(setLoading(true));
  const [surveys, error] = await handleRequest(getSurveys, []);
  if (error) {
    dispatch(setError(error));
  } else {
    dispatch(setSurveys(surveys));
  }
  dispatch(setLoading(false));
};

export const saveSurveyAction = data => async dispatch => {
  dispatch(setLoading(true));
  const [result, error] = await handleRequest(postSurvey, [data]);
  if (error) {
    dispatch(setError(error));
  } else {
    dispatch(saveSurvey(result));
    dispatch(showAlert("success", "Assesment saved successfully"));
  }
  dispatch(setLoading(false));
};

export const deleteSurveyByIdAction = data => async dispatch => {
  dispatch(setLoading(true));
  const [result, error] = await handleRequest(deleteSurveyById, [data]);
  if (error) {
    dispatch(setError(error));
  } else {
    dispatch(deleteSurvey(result || true));
  }
  dispatch(setLoading(false));
};

export const editSurveyAction = data => async dispatch => {
  dispatch(setLoading(true));
  const [result, error] = await handleRequest(editSurvey, [data]);
  if (error) {
    dispatch(setError(error));
  } else {
    dispatch(saveSurvey(result));
    dispatch(showAlert("success", "Assesment updated successfully"));
  }
  dispatch(setLoading(false));
};

export const fetchSurveyByScheduledServiceId = (surveyId) => async (dispatch) => {
  dispatch(setSurveysLoading(true))
  try {
    const result = await axios.get(`${URL.FETCH_SURVEY_BY_TEMPORARY_SERVICE_ID}/${surveyId}`)
    dispatch(setSurveyToTemporaryService(result.data.object))
  }
  catch (error) {
    dispatch(setError(error))
  }
  dispatch(setSurveysLoading(false))
}

export const fetchSurveysByEventId = eventId => async dispatch => {
  dispatch(setSurveysLoading(true))
  const response = await axios.get(`${URL.FIND_SURVEYS_BY_EVENT_ID}/${eventId}`)
  if (response && response.data.success) {
    dispatch(setSurveysByEventId(response.data.objects))
    dispatch(setSurveysLoading(false))
    return response.data.objects[0].survey
  }
  dispatch(setSurveysLoading(false))
}

export const fetchSurveyAnswersByEventId = eventId => async dispatch => {
  dispatch(setSurveyAnswersLoading(true));
  const response = await axios.get(`${URL.FIND_SURVEY_ANSWERS_BY_EVENT_ID}/${eventId}`)
  if (response && response.data.success) {
    dispatch(setSurveyAnswers(response.data.objects));
    dispatch(setSurveyAnswersLoading(false));
    return response?.data?.objects[0]?.questionAnswers
  }
  dispatch(setSurveyAnswersLoading(false));
}

/*
export const fetchFeedbackAnswersByEventId = eventId => async dispatch => {
  dispatch(setFeedbackAnswersLoading(true));
  const response = await axios.get(`${URL.FIND_FEEDBACK_ANSWERS_BY_EVENT_ID}/${eventId}`)
  if (response && response.data.success) {
    dispatch(setSurveyAnswers(response.data.objects));
    dispatch(setFeedbackAnswersLoading(false));
    return response.data.objects[0].questionAnswers
  }
  dispatch(setFeedbackAnswersLoading(false));
}
*/

export const fetchFilteredFeedbackAnswers = (filters, pagination='') => async dispatch => {
  dispatch(setFeedbackAnswersLoading(true));
  const response = await axios.post(`${URL.FILTER_FEEDBACK_ANSWERS}${pagination ? ('?'+pagination) : ''}`,filters)
  if (response && response.data.success) {
    dispatch(setFilteredFeedbackAnswers(response.data.objects));
    dispatch(setFeedbackAnswersLoading(false));
    return response?.data
  }
  dispatch(setFeedbackAnswersLoading(false));
}


