import { FETCH_CUSTOMER_REPORTS_LOADING, FETCH_CUSTOMER_REPORTS_DONE, SET_CUSTOMER_REPORTS } from "actions/reports";
import { eventStatus, boardMode } from "constants.js";

const initialState = {
  isLoading: false,
  info: {
    filename: '',
    data: {
      [eventStatus.checkedIn]: [],
      [eventStatus.started]: [],
      [eventStatus.completed]: [],
      [eventStatus.cancelled]: [],
    },
    headers: [
      { label: 'Provider Name', key: 'providerName' },
      { label: 'Service Name', key: 'serviceName' },
      { label: 'Booking code', key: 'bookingCode' },
      { label: 'Full name', key: 'name' },
      { label: 'Email', key: 'email' },
      //{ label: 'Country Code', key: 'countryCode'},
      { label: 'Phone number', key: 'phone' },
      { label: 'Appointment time', key: 'sslot' },
      { label: 'Booking Status', key: 'status' },
      { label: 'Check-in time', key: 'scheckingTime' },
      { label: 'Service time', key: 'sserviceTime' },
      { label: 'Completed time', key: 'scompletedTime' },
      { label: 'Booking time', key: 'sbookingTime' },
      { label: 'Token', key: 'token'},      
      { label: 'Payment Status', key: 'paymentStatus'}
    ]
  },
};

const sanitizeListCustomer = (list, mode) => {
  return list.map(record => ({
    ...record,
    position: mode === boardMode.queue ? record.position : undefined,
    //phone: `${record.phone} `, //`=""${record.phone}""`,
    //countryCode: `${record.countryCode} `,//`=""${record.countryCode}""`,
    //bookingCode: `${record.bookingCode} `//`=""${record.bookingCode}""`,
  }))
}

const buildReportData = (rawData) => {
  return {
    data: {
      [eventStatus.unspecified]: sanitizeListCustomer(rawData.listCustomersUnspecified, rawData.mode),
      [eventStatus.checkedIn]: sanitizeListCustomer(rawData.listCustomersWaiting, rawData.mode),
      [eventStatus.started]: sanitizeListCustomer(rawData.listCustomersInService, rawData.mode),
      [eventStatus.completed]: sanitizeListCustomer(rawData.listCustomersFinished, rawData.mode),
      [eventStatus.confirmed]: sanitizeListCustomer(rawData.listCustomersConfirmed, rawData.mode),
      [eventStatus.cancelled]: sanitizeListCustomer(rawData.listCustomersCancelled, rawData.mode),
      [eventStatus.noShow]: sanitizeListCustomer(rawData.listCustomersNoShow, rawData.mode),
      [eventStatus.declined]: sanitizeListCustomer(rawData.listCustomersDeclined, rawData.mode),
      [eventStatus.failed]: sanitizeListCustomer(rawData.listCustomersFailed, rawData.mode),
      providerName: rawData.providerName,
      serviceName: rawData.serviceName,
      counterName: rawData?.counterName ?? '',
    },
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_REPORTS_LOADING:
      return { ...state, isLoading: true };
    case FETCH_CUSTOMER_REPORTS_DONE:
      return { ...state, isLoading: false };
    case SET_CUSTOMER_REPORTS:
      return { ...state, info: { ...state.info, ...buildReportData(action.payload) } };
    default:
      return state;
  }
};

export default reducer;
