import React, { useState, useEffect } from 'react'
import { func, array, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import * as Yup from 'yup'
import {
  Select,
  Grid,
  MenuItem,
  FormControl,
  Paper,
  Input,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment
} from '@material-ui/core'
import Button from 'components/CustomButtons/Button';
import { showAlert } from 'actions/alert'
import { fetchGeoLocationOptions } from 'actions/geoOptions'
import { createLocation, editLocation } from 'actions/location';
import GoogleLocationPicker from 'react-location-picker'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';
import selectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

const useStyles = makeStyles({ ...validationFormStyle, ...selectStyle });
const google = window.google;

const LocationPicker = (props) => {

  const { onChange, currentLocationId, type, locationDetails, showModal, onSubmit, onCloseModal, additionalOptions=[] } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const fetchGeoLocations = () => dispatch(fetchGeoLocationOptions());
  const createLocationData = (values) => dispatch(createLocation(values));
  const editLocationData = (values) => dispatch(editLocation(values));
  const availableLocations = useSelector(state => state.options.geo.geoOptions);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0, });
  const [address, setAddress] = useState({});
  const [autoCompleteField, setAutoCompleteField] = useState('');

  useEffect(() => {
    console.log("fetching locations");
    fetchLocations()
  }, [])

  useEffect(() => {
    setShowLocationModal(showModal)
  }, [showModal])

  useEffect(() => {
    if (locationDetails?.coordinates) {
      let cordinate = {
        lat: locationDetails?.coordinates?.latitude ?? 0,
        lng: locationDetails?.coordinates?.longitude ?? 0,
      }
      setCoordinates(cordinate)
    }
    setAutoCompleteField("");
  }, [locationDetails])

  const fetchLocations = async () => {
    const availableLocations = await fetchGeoLocations()
    if (currentLocationId) {
      setSelectedLocationId(availableLocations.find(item => (item.value === currentLocationId))?.value ?? '');
    }
  }

  const onSelectLocation = (event) => {
    setSelectedLocationId(event.target.value);
    onChange(event.target.value);
  }

  const openLocationModal = () => {
    setShowLocationModal(true);
  }

  const closeLocationModal = () => {
    setShowLocationModal(false);
    if (onCloseModal)
      onCloseModal()
  }

  const handleAddressSelect = async (address, setFieldValue) => {
    if(!google?.maps) {
      console.log("Google API Script is not loaded");
      return;
    }
    try {
      const geocode = await geocodeByAddress(address);
      if (geocode?.length) {
        const coordinate = await getLatLng(geocode[0]);
        let parsedGeocode = parseGeoCode(geocode, address)
        console.log("Address >>", address, "parsedGeocode", parsedGeocode, coordinate)


        if (parsedGeocode.streetAddress) {
          setFieldValue("streetAddress", parsedGeocode.streetAddress);
          setFieldValue("name", parsedGeocode.streetAddress);
        }
        if (parsedGeocode.postCode) {
          setFieldValue("postCode", parsedGeocode.postCode);
        }
        if (parsedGeocode.city) {
          setFieldValue("city", parsedGeocode.city);
        }
        if (parsedGeocode.district) {
          setFieldValue("district", parsedGeocode.district);
        }
        if (parsedGeocode.state) {
          setFieldValue("state", parsedGeocode.state);
        }
        if (parsedGeocode.country) {
          setFieldValue("country", parsedGeocode.country);
        }

        setAddress(parsedGeocode);
        if (coordinate?.lat && coordinate?.lng)
          setCoordinates({ lat: coordinate?.lat, lng: coordinate?.lng });
      }
    }
    catch (error) {
      showAlert(error)
    }
  }

  const parseGeoCode = (geocodes, address = "") => {
    console.log("geocodes>>", geocodes)
    let data = {};
    data.streetAddress = address.split(",")[0];
    for (let geocode of geocodes) {
      console.log("geocode 1 >> ", geocode)
      let addressComponents = geocode.address_components;

      for (let component of addressComponents) {
        if (component?.long_name && component?.types && component?.types?.includes("postal_code")) {
          data.postCode = component?.long_name
        }
        if (component?.long_name && component?.types && component?.types?.includes("country")) {
          data.country = component?.long_name
        }
        if (component?.long_name && component?.types && component?.types?.includes("administrative_area_level_1")) {
          data.state = component?.long_name
        }
        if (component?.long_name && component?.types && component?.types?.includes("administrative_area_level_2")) {
          data.district = component?.long_name
        }
        if (component?.long_name && component?.types && component?.types?.includes("locality")) {
          data.city = component?.long_name
        }
        if (component?.long_name && component?.types &&
          (component?.types?.includes("route") ||
            component?.types?.includes("sublocality") ||
            component?.types?.includes("neighborhood"))) {
          data.streetAddress = data?.streetAddress ? data.streetAddress + ", " + component?.long_name : component?.long_name;
        }
      }

    };
    return data;
  }

  const handleCoordinatesChange = ({ position, address, places }) => {
    // Set new location
    if (position.lat)
      setCoordinates(position);
    //if (places[0])
    //  setAddress(places[0])
    //console.log( position, address, places )
  }

  const handleAddressChange = (value) => {
    setAutoCompleteField(value)
  }

  const onSubmitLocation = async (values) => {

    values.coordinates = {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    }
    let responseObject;
    if (locationDetails?.id) {
      values.id = locationDetails?.id;
      responseObject = await editLocationData(values)
    }
    else {
      responseObject = await createLocationData(values)
    }
    if (responseObject) {
      closeLocationModal()
      if (onSubmit)
        onSubmit(responseObject)
    }

  }


  return (
    <>
      {type == 'modal' ?
        null
        :
        <GridContainer alignItems="center">
          {availableLocations?.length ? 
          <GridItem style={{flex:1,width:'100%'}}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                onChange={onSelectLocation}
                value={selectedLocationId}
                inputProps={{
                  id: 'location-select',
                  name: "locationSelector"
                }}
                displayEmpty
              >
                {additionalOptions.map(
                  (location, index) => (
                      <MenuItem
                        key={index}
                        value={location.value}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                      >
                        {location.label}
                      </MenuItem>
                    ))
                }
                {
                  availableLocations.map(
                    (location, index) => (
                      <MenuItem
                        key={index}
                        value={location.value}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                      >
                        {location.label}
                      </MenuItem>
                    )
                  )
                }
              </Select>
            </FormControl>
          </GridItem>
          :null
          }
          <GridItem>
            <Button
              size='sm'
              variant='contained'
              color='rose'
              onClick={openLocationModal}
              style={{ marginTop: 25 }}
            >
              New Location
            </Button>
          </GridItem>
        </GridContainer>
      }
      <Dialog
        open={showLocationModal ?? false}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>
          {locationDetails?.id ? "Edit Location" : "Add Location"}
          <IconButton
            style={{
              position: 'absolute',
              color: 'grey',
              right: '14px',
              top: '10px',
            }}
            onClick={closeLocationModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <GridContainer
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            style={{ width: '100%' }}
            wrap="wrap-reverse"
          >
            <GridItem md={6} >
              <Formik
                initialValues={{
                  streetAddress: locationDetails?.streetAddress ?? '',
                  district: locationDetails?.district ?? '',
                  city: locationDetails?.city ?? '',
                  country: locationDetails?.country ?? '',
                  postCode: locationDetails?.postCode ?? '',
                  state: locationDetails?.state ?? '',
                  name: locationDetails?.name ?? '',
                }}
                onSubmit={onSubmitLocation}
                validationSchema={locationSchema}
                children={
                  ({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    setFieldValue
                  }) => (
                    <GridContainer>
                      <GridItem xs={12} md={12}>
                        { google?.maps ? 
                        <PlacesAutocomplete
                          value={autoCompleteField}
                          onChange={handleAddressChange}
                          onSelect={place => handleAddressSelect(place, setFieldValue)}
                          debounce={500}
                        >
                          {
                            ({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
                              <div >
                                <CustomInput
                                  id="search"
                                  inputProps={{
                                    autoFocus: locationDetails?.id ? false : true,
                                    placeholder: 'Search Location',
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <SearchIcon />
                                      </InputAdornment>
                                    ),
                                    ...getInputProps()
                                  }}
                                  formControlProps={{ fullWidth: true }}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {
                                    loading && <div>Loading...</div>
                                  }
                                  <Paper
                                    elevation={5}
                                    square={false}
                                  >
                                    {
                                      suggestions.map(
                                        (suggestion, index) => {
                                          const style = suggestion.active
                                            ? { padding: 5, backgroundColor: '#7d8484', cursor: 'pointer', color: '#ffffff' }
                                            : { padding: 5, backgroundColor: '#ffffff', cursor: 'pointer' }
                                          return (
                                            <div key={index}
                                              {...getSuggestionItemProps(suggestion, { style })}
                                            >
                                              <span>{suggestion.description}</span>
                                            </div>
                                          )
                                        }
                                      )
                                    }
                                  </Paper>
                                </div>
                              </div>
                            )
                          }
                        </PlacesAutocomplete>
                        :null
                        }
                      </GridItem>
                      <GridItem md={12}>
                        <CustomInput
                          id="name"
                          labelText='Display Name'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.name
                          }}
                          formControlProps={{ fullWidth: true }}
                          helperText="Display Name will be shown instead of the full address, If mentioned"
                        />
                        <CustomInput
                          id="streetAddress"
                          labelText='Street Address'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '100',
                            onChange: handleChange,
                            value: values.streetAddress
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.streetAddress && touched.streetAddress ? (
                            <div
                              style={{ color: 'red' }}
                            >
                              {errors.streetAddress}
                            </div>
                          )
                            : null
                        }
                        <CustomInput
                          id="city"
                          labelText='City'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.city
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.city && touched.city ? (
                            <div
                              style={{ color: 'red' }}
                            >
                              {errors.city}
                            </div>
                          )
                            : null
                        }
                        <CustomInput
                          id="district"
                          labelText='District'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.district
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.district && touched.district ? (
                            <div style={{ color: 'red' }}>{errors.district}</div>
                          )
                            : null
                        }
                        <CustomInput
                          id="state"
                          labelText='State'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.state
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.state && touched.state ? (
                            <div style={{ color: 'red' }}>{errors.state}</div>
                          )
                            : null
                        }
                        <CustomInput
                          id="country"
                          labelText='Country'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.country
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.country && touched.country ? (
                            <div style={{ color: 'red' }}>{errors.country}</div>
                          )
                            : null
                        }
                        <CustomInput
                          id="postCode"
                          labelText='Post Code'
                          inputProps={{
                            disabled: false,
                            type: 'text',
                            maxLength: '50',
                            onChange: handleChange,
                            value: values.postCode
                          }}
                          formControlProps={{ fullWidth: true }}
                        />
                        {
                          errors && errors.postCode && touched.postCode ? (
                            <div
                              style={{ color: 'red' }}
                            >
                              {errors.postCode}
                            </div>
                          )
                            : null
                        }
                      </GridItem>
                      <GridContainer
                        justifyContent='space-around'
                        alignItems='center'
                      >
                        <Button
                          onClick={closeLocationModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="rose"
                          onClick={handleSubmit}
                        >
                          {locationDetails?.id ? "Edit Location" : "Add Location"}
                        </Button>
                      </GridContainer>
                    </GridContainer>
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} md={6} >
              <GridContainer justifyContent="center" >
                <GoogleLocationPicker
                  containerElement={<div style={{ height: '100%' }} />}
                  mapElement={
                    <div
                      style={{
                        height: '300px',
                        width: '450px',
                      }}
                    />
                  }
                  defaultPosition={coordinates}
                  onChange={handleCoordinatesChange}
                  radius={-1}
                  zoom={15}
                />
              </GridContainer>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  )
}

const locationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Please enter a streetAddress'),
  city: Yup.string().required('Please enter a city'),
  country: Yup.string().required('Please enter a valid Country'),
  /*postCode: Yup.string().required('Please enter a valid Postal code'),*/
  /*state: Yup.string().required('Please enter a valid State')*/
})


Location.propTypes = {
  onChange: func.isRequired,
  currentLocationId: string,
}



export default LocationPicker
