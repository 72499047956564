import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Datetime from "react-datetime";
import { map, isEmpty } from 'lodash';
import {
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Switch,
  Tooltip,
  Checkbox,
  Grid,
  FormControl,
  TextField,
  Radio,
  Chip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Check from "@material-ui/icons/Check";

import InfoIcon from '@material-ui/icons/Info';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from "components/CustomButtons/Button";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CustomPhoneNumberInput from 'components/CustomPhoneNumberInput/CustomPhoneNumberInput';
import ImageUpload from 'components/custom/ImagePicker/ImageUpload';
import LocationPicker from 'components/Location/LocationPicker.js';
import WorkingHoursPicker from 'components/custom/WorkingHoursPicker/WorkingHoursPicker';
import { roseColor } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';

import { defaultReportTime, defaultWorkingHoursObj } from 'constants.js';
import { fetchTimezoneOptions } from 'actions/timezoneOptions';
//import moment from 'moment';
import moment from 'moment-timezone';
import zones from 'moment-timezone/data/meta/latest.json';
import defaultImage from 'assets/img/service-defaultimage.jpg';
import { serviceMode, weekDays } from '../../../constants';
import {
  EVENT_LEVEL, EVENT_TYPE, EVENT_TYPE_TITLE,
  EVENT_REPEAT_TYPE, REPEAT_END_TYPE,
  REPEAT_EVERY_DEF, REPEAT_DATE_DEF, PROVIDER_EVENT_TYPE
} from 'constants/Calendar.constants';

import { makeStyles } from "@material-ui/core/styles";
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormStyle';
const useStyles = makeStyles(validationFormStyle);

const ServiceCreate = (props, itemRef) => {


  const {allStates} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeZoneOptions = useSelector(state => state.options.timezone.tzOptions);
  const geoOptions = useSelector(state => state.options.geo.geoOptions);
  const dispatchFetchTimezoneOptions = () => dispatch(fetchTimezoneOptions());

  const [showLocationAddEditModal, setShowLocationAddEditModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    dispatchFetchTimezoneOptions();
  }, [])

  const ScheduledServiceCreateSchema = Yup.object().shape({
    providerId: Yup.string().required("This field is required"),
    serviceId: Yup.string().required("This field is required"),
    geoLocationId: Yup.string().required("This field is required"),
    customLocation: Yup.string().when('geoLocationId', (geoLocationId) => geoLocationId == "custom" ?
        Yup.string().trim().required("Please enter a location or address").min(1, "Location should be at least 1 characters length").max(50, "Location should not be at more than 50 characters")
        : Yup.string().notRequired()
    ),
    description: Yup.string()
      .min(5, "Description too short")
      .max(200),
    duration: Yup.number()
      .min(1),
    numberOfParallelCustomer: Yup.number()
      .min(1).max(999),
  })

  const startTime =  moment().set({ hour: 8, minute: 0 }).unix();
  const endTime = moment().set({ hour: 18, minute: 0 }).unix();
  const nowUnix = moment().unix();

  const formik = useFormik({
    initialValues: {
      description: '',
      providerId: '',
      slot: {
        startTime: startTime,
        endTime: endTime,
      },
      type: "TMP_SERVICE",
      timezoneId: "Asia/Kolkata",
      avgServiceTime: 10,
      breakTime: {
        breakStart: null,
        breakEnd: null
      },
      notifEmail: '',
      isOnline: false,
      ownerId: '',
      campaignId: '',
      corporateId: '',
      geoLocationId: '',
      customLocation: '',
      numberOfParallelCustomer: 1,
      serviceId: '',
      surveyId: null,
      privacy: true,
      /* Non-api fields */
      repeat: {
        type: 'Never',
        everyDate: REPEAT_DATE_DEF,
        every: 1,
        repeatEnd: {},
      },
      eventStartDateUnix: nowUnix,
      eventStartFromTimeUnix: startTime,
      eventStartToTimeUnix: endTime,
      selectedProvider: null,
      selectedService: null,
    },
    validationSchema: ScheduledServiceCreateSchema,
    onSubmit: values => { },
    enableReinitialize: false
  });

  const {
    values,
    errors,
    status,
    isValid,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setTouched,
    setFieldTouched,
    validateForm,
  } = formik;


  const wizardSubmit = async (allStates) => {
    
    setTouched({
      providerId: true,
      serviceId: true,
      geoLocationId: true,
      customLocation : true,
    }, true);//touches all fields
    

    const errors = await validateForm();
    const errorFields = Object.keys(errors);
    let payload = formik.values;
    //console.log("wizard Submit ScheduledServiceCreate payload >>", payload)
    //console.log("wizard Submit ScheduledServiceCreate validateForm >>", errors)

    const resp = errorFields?.length ? false : payload;
    return resp;
  }


  useImperativeHandle(itemRef, () => ({
    wizardSubmit: wizardSubmit
  }))


  const onBlurValidation = (event , field ) => {
    if (field == "numberOfParallelCustomer" || field == "repeat.repeatEnd.afterOccur") {
      if (!parseInt(event.target.value))
        setFieldValue(field, 1);
    }

    if (field == "avgServiceTime") {
      if (!parseInt(event.target.value))
        setFieldValue(field, 10);
    }
  }

  const onSelectRepeatType = ({ target: { value: repeatType } }) => {
    setFieldValue('repeat.type', repeatType);
    setFieldValue('repeat.every', repeatType === EVENT_REPEAT_TYPE.NEVER ? 0 : REPEAT_EVERY_DEF[repeatType][0]);
    setFieldValue('repeat.everyDate', repeatType === EVENT_REPEAT_TYPE.WEEKLY
      ? [moment(values.slot.startTime).format('dddd')]
      : values.repeat.everyDate || [REPEAT_DATE_DEF[0]]);
    setFieldValue('repeat.repeatEnd', {});
  }

  const onRepeatEndSelect = ({ target: { value } }) => {  
    const type = value;
    let data = {};
    if (type === REPEAT_END_TYPE.AFTER_NUM_OCCUR) {
      data = { ...data, afterOccur: values?.repeat?.repeatEnd?.afterOccur ?? 30 };
    }
    if (type === REPEAT_END_TYPE.ON_DATE) {
      data = {
        ...data,
        onDate: moment(values.eventStartDateUnix * 1000)
          .clone()
          .add(1, 'month')
          .endOf('day')
          .format()
      };
    }
    setFieldValue('repeat.repeatEnd', data );
  };


  const onChangeDateTime = (date, field, type = "time") => {
    let dateObj = date;
    let format = type == "time" ? 'hh:mm A' : "MMM Do";
    if (typeof date === 'string') {
      const unix = moment(date, format).unix();
      if (!unix) return;
      dateObj = moment(unix * 1000);
    }
    if (!dateObj) return;

    const time = moment(dateObj);
    let unixTimestamp = time.unix();

    if (field == "eventStartDate") {
      let startUnixTimestamp = time.unix();
      let endUnixTimestamp = time.unix();
      console.log("eventStartDate >>", values, unixTimestamp)
      if (values.eventStartFromTimeUnix) {
        const startTime = moment(values.eventStartFromTimeUnix * 1000);
        let startTimeObj = {
          hour: startTime.hour(),
          minute: startTime.minutes()
        };
        startUnixTimestamp = time.set(startTimeObj).unix();

      }
      if (values.eventStartToTimeUnix) {
        const endTime = moment(values.eventStartToTimeUnix * 1000);
        let endTimeObj = {
          hour: endTime.hour(),
          minute: endTime.minutes()
        };
        endUnixTimestamp = time.set(endTimeObj).unix();
      }
      setFieldValue('eventStartDateUnix', startUnixTimestamp);
      setFieldValue('eventStartFromTimeUnix', startUnixTimestamp);
      setFieldValue('slot.startTime', startUnixTimestamp);
      setFieldValue('eventStartToTimeUnix', endUnixTimestamp);
      setFieldValue('slot.endTime', endUnixTimestamp);
    }
    if (field == "eventStartFromTime") {
      let timeObj = {
        hour: time.hour(),
        minute: time.minutes()
      };
      if (values.eventStartDateUnix) {
        unixTimestamp = moment(values.eventStartDateUnix * 1000).set(timeObj).unix();
      }
      setFieldValue('eventStartDateUnix', unixTimestamp);
      setFieldValue('eventStartFromTimeUnix', unixTimestamp);
      setFieldValue('slot.startTime', unixTimestamp);
    }
    if (field == "eventStartToTime") {
      let timeObj = {
        hour: time.hour(),
        minute: time.minutes()
      };
      if (values.eventStartDateUnix) {
        unixTimestamp = moment(values.eventStartDateUnix * 1000).set(timeObj).unix();
      }
      setFieldValue('eventStartToTimeUnix', unixTimestamp);
      setFieldValue('slot.endTime', unixTimestamp);
    }

    if (field == "breakFromTime") {
      setFieldValue('breakTime.breakStart', unixTimestamp);
    }
    if (field == "breakToTime") {
      setFieldValue('breakTime.breakEnd', unixTimestamp);
    }
    if (field == "eventRepeatEndDate") {
      let date = time.endOf('day').format();
      setFieldValue('repeat.repeatEnd', { onDate: date });
    }
  }

  const selectLocation = (event) => {
    //alert(event.target.value);
    if (event.target.value === "custom" || event.target.value === "online") {
      setFieldValue('customLocation', "");
    }
    else {
      setFieldValue('customLocation', "");
    }
    setFieldValue("geoLocationId", event.target.value);
  }

  const onSubmitAddEditLocation=(data)=>{
    setShowLocationAddEditModal(false);
    setFieldValue("geoLocationId", data?.id);
    setFieldValue('customLocation', "");
  }

  const selectProvider = (event) => {
    const providerEmail = event.target.value;
    setFieldValue("providerId", providerEmail);
    if(providerEmail && allStates?.providerPayloadArray?.length ){
      const selectedProvider = allStates.providerPayloadArray.find(p => p.email === providerEmail);
      const momentData = moment(values.slot.startTime * 1000);
      const weekDay = weekDays[momentData.day()];
      const workingHour = selectedProvider.providerInformation.workingHours.find(wh => wh.day === weekDay);
      
      if(workingHour){
        const startTimeUnix = moment()
          .year(momentData.year())
          .month(momentData.month())
          .date(momentData.date())
          .hour(workingHour.startTime.hour)
          .minute(workingHour.startTime.minute)
          .second(0)
          .unix()
        const endTimeUnix = moment()
          .year(momentData.year())
          .month(momentData.month())
          .date(momentData.date())
          .hour(workingHour.endTime.hour)
          .minute(workingHour.endTime.minute)
          .second(0)
          .unix()
        if(startTimeUnix !== endTimeUnix){    
          //console.log("select Provider,", startTimeUnix , endTimeUnix);
          setFieldValue('eventStartDateUnix', startTimeUnix);
          setFieldValue('eventStartFromTimeUnix', startTimeUnix);
          setFieldValue('slot.startTime', startTimeUnix);

          setFieldValue('eventStartToTimeUnix', endTimeUnix);
          setFieldValue('slot.endTime', endTimeUnix);
        }
      }
    }
  }

  const selectService = (event)=>{
    const serviceName = event.target.value;
    const service = allStates?.servicePayloadArray.find(item=> item?.name?.toLowerCase()?.trim() === serviceName );
    setFieldValue("serviceId", serviceName);
    if(service?.duration){
      setFieldValue("avgServiceTime", service?.duration);
    }
  }

  useEffect(()=>{
    console.log("allState changed (tmpService) >> ",allStates)
    if(allStates?.providerPayloadArray?.length){
      setProviders(allStates.providerPayloadArray);
      //const email = allStates?.providerPayloadArray[0]?.email;
      //selectProvider({target:{value:email}});
    }
    if(allStates?.servicePayloadArray?.length){
      setServices(allStates.servicePayloadArray);
      //const serviceName = allStates?.servicePayloadArray[0]?.name?.toLowerCase()?.trim();
      //setFieldValue("serviceId",serviceName);
    }
  },[allStates])

  return (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <form>
          <Card>
            {/* 
              <CardHeader color="rose" text>
                  <CardText color="rose">
                      <h4 className={classes.cardTitle}>Create Provider</h4>
                  </CardText>
              </CardHeader>
            */}
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Service *</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      value={values.serviceId}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      onChange={selectService}
                      name="serviceId"
                      inputProps={{
                        name: "serviceId",
                        id: "serviceId",
                      }}
                    >
                      {services.map(
                        (service, index) => (
                          <MenuItem
                            key={index}
                            value={service?.name?.toLowerCase()?.trim()}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            {service.name}
                          </MenuItem>
                        )
                      )
                      }
                    </Select>
                  </FormControl>
                  {errors.serviceId && touched.serviceId && (
                    <div style={{ color: "red" }}>{errors.serviceId}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Provider *</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      value={ values.providerId}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      onChange={selectProvider}
                      name="providerId"
                      inputProps={{
                        name: "providerId",
                        id: "providerId",
                      }}
                    >
                      {providers.map(
                        provider => (
                          <MenuItem
                            key={provider.email}
                            value={provider.email}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            {provider.givenName} {provider.familyName}
                          </MenuItem>
                        )
                      )
                      }
                    </Select>
                  </FormControl>
                  {errors.providerId && touched.providerId && (
                    <div style={{ color: "red" }}>{errors.providerId}</div>
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Time zone</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      value={timeZoneOptions?.length ? values.timezoneId : ''}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      onChange={handleChange}
                      name="timezoneId"
                      inputProps={{
                        name: "timezoneId",
                        id: "timezoneId",
                      }}
                    >
                      {timeZoneOptions.map(
                        timezone => (
                          <MenuItem
                            key={timezone.value}
                            value={timezone.label}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                          >
                            {timezone.label}
                          </MenuItem>
                        )
                      )
                      }
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Event Start Date</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <FormControl fullWidth className={classes.checkBoxContainer}>
                    <Datetime
                      dateFormat="MMM Do"
                      timeFormat={false}
                      inputProps={{ placeholder: "Select Start Date" }}
                      formControlProps={{ fullWidth: true }}
                      onChange={(date) => onChangeDateTime(date, "eventStartDate", "date")}
                      value={moment(values.eventStartDateUnix * 1000).toDate()}
                      isValidDate={ current => current.isAfter(moment().subtract( 1, 'day' ))}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>From &nbsp;
                    <Tooltip title="Schedule service time should match with provider working hours">
                      <InfoIcon style={{ width: '.9rem' }} />
                    </Tooltip>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <GridContainer alignItems="flex-start" justifyContent="flex-start">
                    <GridItem xs={5} md={5} lg={4}>
                      <FormControl fullWidth className={classes.checkBoxContainer}>
                        <Datetime
                          dateFormat={false}
                          inputProps={{
                            placeholder: 'Start Time',
                            name: `startTime`,
                            autocomplete:'off',
                          }}
                          formControlProps={{ fullWidth: true }}
                          onChange={(date) => onChangeDateTime(date, "eventStartFromTime")}
                          value={moment(values.eventStartFromTimeUnix * 1000).toDate()}
                          timeConstraints={{minutes:{step:5}}}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={2} md={2} lg={2}><label className={classes.label} style={{ paddingTop: 30, width: '100%', justifyContent: 'center' }}> TO </label></GridItem>
                    <GridItem xs={5} md={5} lg={4}>
                      <FormControl fullWidth className={classes.checkBoxContainer} >
                        <Datetime
                          dateFormat={false}
                          inputProps={{
                            placeholder: 'End Time',
                            name: `endTime`,
                            autocomplete:'off',
                          }}
                          formControlProps={{ fullWidth: true }}
                          onChange={(date) => onChangeDateTime(date, "eventStartToTime")}
                          value={moment(values.eventStartToTimeUnix * 1000).toDate()}
                          className="drop-to-the-right"
                          timeConstraints={{minutes:{step:5}}}
                        />

                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Description</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    id="description"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      maxLength: 200,
                      value: values.description,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Break Time</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <GridContainer alignItems="flex-start" justifyContent="flex-start">
                    <GridItem xs={5} md={5} lg={4}>
                      <FormControl fullWidth className={classes.checkBoxContainer}>
                        <Datetime
                          dateFormat={false}
                          inputProps={{
                            placeholder: 'Start from ' + moment(values.eventStartFromTimeUnix * 1000).format("hh:mm A"),
                            name: `breakFromTime`,
                            autocomplete:'off',
                          }}
                          formControlProps={{ fullWidth: true }}
                          onChange={(date) => onChangeDateTime(date, "breakFromTime")}
                          value={values?.breakTime?.breakStart ? moment(values.breakTime.breakStart * 1000).toDate() : null}
                          timeConstraints={{minutes:{step:5}}}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={2} md={2} lg={2}><label className={classes.label} style={{ paddingTop: 30, width: '100%', justifyContent: 'center' }}> TO </label></GridItem>
                    <GridItem xs={5} md={5} lg={4}>
                      <FormControl fullWidth className={classes.checkBoxContainer} >
                        <Datetime
                          dateFormat={false}
                          inputProps={{
                            placeholder: 'End before ' + moment(values.eventStartToTimeUnix * 1000).format("hh:mm A"),
                            name: `breakToTime`,
                            autocomplete:'off',
                          }}
                          formControlProps={{ fullWidth: true }}
                          onChange={(date) => onChangeDateTime(date, "breakToTime")}
                          value={values?.breakTime?.breakEnd ? moment(values.breakTime.breakEnd * 1000).toDate() : null}
                          className="drop-to-the-right"
                          timeConstraints={{minutes:{step:5}}}
                        />

                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              {/* Repeat Block Start */}
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Repeat</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      value={values.repeat.type}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      onChange={onSelectRepeatType}
                      name="repeat.type"
                      inputProps={{
                        name: "repeat.type",
                        id: "repeat.type",
                      }}
                    >
                      {map(EVENT_REPEAT_TYPE, value => (
                        <MenuItem
                          value={value}
                          key={`EVENT_REPEAT_TYPE-${value}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {values.repeat.type !== EVENT_REPEAT_TYPE.NEVER && (
                  <>
                    <GridItem xs={4} sm={4} md={1}>
                      <FormLabel className={classes.labelHorizontal} >On Every</FormLabel>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={1}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <Select
                          value={values.repeat.every}
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          onChange={handleChange}
                          name="repeat.every"
                          inputProps={{
                            name: "repeat.every",
                            id: "repeat.every",
                          }}
                        >
                          {map(REPEAT_EVERY_DEF[values.repeat.type], value => (
                            <MenuItem
                              value={value}
                              key={`REPEAT_EVERY_DEF-${value}`}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={1}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ float: 'left' }}
                      >
                        {(() => {
                          if (values.repeat.type === EVENT_REPEAT_TYPE.DAILY) {
                            return values.repeat.every === 1 ? 'day' : 'days';
                          }
                          return values.repeat.every === 1 ? 'week' : 'weeks';
                        })()}
                      </FormLabel>
                    </GridItem>
                    {values.repeat.type === EVENT_REPEAT_TYPE.WEEKLY && (
                      <>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel className={classes.labelHorizontal} >Repeat On</FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={6}>
                          <FormControl fullWidth className={classes.selectFormControl}>
                            <Select
                              multiple
                              name="repeat.everyDate"
                              value={values.repeat.everyDate}
                              onChange={handleChange}
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              renderValue={selected => (
                                <Chip
                                  key={`REPEAT_DATE_DEF-${selected.join(', ')}-Chip`}
                                  label={selected.join(', ')}
                                />
                              )}
                            >
                              {REPEAT_DATE_DEF.map(dateDef => (
                                <MenuItem
                                  key={dateDef}
                                  value={dateDef}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple,
                                  }}>
                                  {dateDef}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </>)
                    }
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel className={classes.labelHorizontal} >Repeat Ends</FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={6}>
                      <GridContainer className={classes.radioContainer}>
                        <GridItem>
                          <FormControlLabel
                            control={
                              <Radio
                                name="repeat.repeatEnd"
                                value={REPEAT_END_TYPE.NEVER}
                                onChange={(e) => onRepeatEndSelect(e)}
                                checked={isEmpty(values.repeat.repeatEnd)}
                              />}
                            classes={{
                              label: classes.label
                            }}
                            label={
                              <GridContainer alignItems="center">
                                <GridItem>
                                  <FormLabel className={classes.label} >Never</FormLabel>
                                </GridItem>
                              </GridContainer>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem>
                          <FormControlLabel
                            control={
                              <Radio
                                name="repeat.repeatEnd.afterOccur"
                                value={REPEAT_END_TYPE.AFTER_NUM_OCCUR}
                                onChange={(e) => onRepeatEndSelect(e)}
                                checked={(values?.repeat?.repeatEnd?.afterOccur ? true : false)}
                              />}
                            classes={{
                              label: classes.label
                            }}
                            label={
                              <GridContainer alignItems="center">
                                <GridItem>
                                  <FormLabel className={classes.label} >After</FormLabel>
                                </GridItem>
                                <GridItem>
                                  <CustomInput
                                    id="repeat.repeatEnd.afterOccur"
                                    formControlProps={{
                                      fullWidth: true,
                                      style: { paddingTop: 0 }
                                    }}
                                    inputProps={{
                                      type: 'number',
                                      inputProps:{
                                        min: '1',
                                      },
                                      value: values.repeat.repeatEnd.afterOccur || '',
                                      onChange: handleChange,
                                      onBlur: e => onBlurValidation(e ,"repeat.repeatEnd.afterOccur" )
                                    }} />
                                </GridItem>
                                <GridItem>
                                  <FormLabel className={classes.label} > occurrences</FormLabel>
                                </GridItem>
                              </GridContainer>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem>
                          <FormControlLabel
                            control={
                              <Radio
                                name="repeat.repeatEnd.onDate"
                                value={REPEAT_END_TYPE.ON_DATE}
                                onChange={(e) => onRepeatEndSelect(e)}
                                checked={(values?.repeat?.repeatEnd?.onDate ? true : false)}
                              />}
                            classes={{
                              label: classes.label
                            }}
                            label={
                              <GridContainer alignItems="center">
                                <GridItem>
                                  <FormLabel className={classes.label} >On </FormLabel>
                                </GridItem>
                                <GridItem>
                                  <FormControl fullWidth >
                                    <Datetime
                                      dateFormat="MMM Do"
                                      timeFormat={false}
                                      inputProps={{ placeholder: "Select Repeat End Date" }}
                                      formControlProps={{ fullWidth: true }}
                                      onChange={(date) => onChangeDateTime(date, "eventRepeatEndDate", "date")}
                                      value={moment(values.repeat.repeatEnd.onDate).toDate()}
                                      isValidDate={ current => current.isAfter(moment().add( 1, 'day' ))}
                                    />
                                  </FormControl>
                                </GridItem>
                              </GridContainer>
                            }
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </>
                )
                }
              </GridContainer>
              {/* Repeat Block End */}
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Service Time *</FormLabel>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    id="avgServiceTime"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'number',
                      inputProps:{
                        min: '1',
                      },
                      value: values.avgServiceTime,
                      onChange: handleChange,
                      onBlur: e=>onBlurValidation(e,"avgServiceTime")
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={2}>
                  <FormLabel className={classes.labelHorizontal} style={{ float: 'left' }}>
                    minutes
                  </FormLabel>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Parallel customers</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    id="numberOfParallelCustomer"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'number',
                      inputProps:{
                        min: '1',
                      },
                      value: values.numberOfParallelCustomer,
                      onChange: handleChange,
                      onBlur: e=>onBlurValidation(e,"numberOfParallelCustomer")
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Additional Notification Email</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <CustomInput
                    id="notifEmail"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      maxLength: 50,
                      value: values.notifEmail,
                      onChange: handleChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Location *</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <GridContainer>
                    <GridItem style={{ flex: 1, width: '100%' }}>
                      <FormControl fullWidth className={classes.selectFormControl} style={{ minWidth: 100 }}>
                        <Select
                          value={values.geoLocationId}
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          onChange={(e) => selectLocation(e)}
                          name="geoLocationId"
                          inputProps={{
                            name: "geoLocationId",
                            id: "geoLocationId",
                          }}
                        >
                          <MenuItem
                            value="custom"
                            key={"custom"}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}>
                            Custom location
                          </MenuItem>
                          {geoOptions.map(
                            geoOption => (
                              <MenuItem
                                key={geoOption.value}
                                value={geoOption.value}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                              >
                                {geoOption.label}
                              </MenuItem>
                            )
                          )
                          }
                        </Select>
                      </FormControl>
                      {errors.geoLocationId && touched.geoLocationId && (
                        <div style={{ color: "red" }}>{errors.geoLocationId}</div>
                      )}
                    </GridItem>
                    {(values.geoLocationId=="custom" || values.geoLocationId=="online" ) &&
                      <GridItem style={{ flex: 1 }} sm={12}>
                        <FormControl style={{ minWidth: 100 }}>
                          <CustomInput
                            id="customLocation"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              maxLength: 50,
                              placeholder: 'Enter your Location',
                              value: values.customLocation,
                              onChange: handleChange
                            }}
                          />
                        </FormControl>
                        {errors.customLocation && touched.customLocation && (
                          <div style={{ color: "red" }}>{errors.customLocation}</div>
                        )}
                      </GridItem>
                    }
                      <GridItem style={{ flex: 0 }}>
                        <FormControl className={classes.selectFormControl} >
                          <Button size="sm" variant="contained" color="rose" onClick={()=>setShowLocationAddEditModal(true)}>
                            New location
                          </Button>
                        </FormControl>
                        <LocationPicker
                          type="modal"
                          showModal={showLocationAddEditModal}
                          onSubmit={(e)=>onSubmitAddEditLocation(e)}
                          onCloseModal={()=>setShowLocationAddEditModal(false)}
                        />
                      </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel className={classes.labelHorizontal}>Visibility</FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={6}>
                  <FormControlLabel
                    className={classes.checkBoxContainer}
                    control={
                      <Checkbox
                        tabIndex={-1}
                        name="privacy"
                        checked={values.privacy}
                        onChange={handleChange}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label:
                        classes.label
                    }}
                    label={'The service will be visible to Customers'}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
            </CardFooter>
          </Card>
        </form>
      </GridItem>
    </>
  )

}
export default forwardRef(ServiceCreate);