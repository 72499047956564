import {
  SET_SUPER_ADMIN_PREFERENCES,
  SET_SUPER_ADMIN_PREFERENCES_LOADING,
  SET_NOTIF_LISTS,
  SET_NOTIF_LISTS_LOADING,
  SET_FILTERED_CUSTOMER_REVIEWS,
  SET_FILTERED_CUSTOMER_REVIEWS_LOADING

} from "actions/superAdmin";


const initialState = {
  notifLists: [],
  notifListsLoading: false,
  preferences: {},
  preferencesLoading: false,
  filteredCustomerReviews: [],
  filteredCustomerReviewsLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIF_LISTS:
      return {
        ...state,
        notifLists: action.payload,
      };
    case SET_NOTIF_LISTS_LOADING:
      return {
        ...state,
        notifListsLoading: action.payload,
      };
    case SET_SUPER_ADMIN_PREFERENCES:
      return {
        ...state,
        preferences: action.payload,
      };
    case SET_SUPER_ADMIN_PREFERENCES_LOADING:
      return {
        ...state,
        preferencesLoading: action.payload,
      };
    case SET_FILTERED_CUSTOMER_REVIEWS:
      return {
        ...state,
        filteredCustomerReviews: action.payload,
      };
    case SET_FILTERED_CUSTOMER_REVIEWS_LOADING:
      return {
        ...state,
        filteredCustomerReviewsLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;