import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
    FormLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Switch,
    Tooltip,
    Grid,
    FormControl
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import IconButton from '@material-ui/core/IconButton'

import InfoIcon from '@material-ui/icons/Info';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import CustomPhoneNumberInput from 'components/CustomPhoneNumberInput/CustomPhoneNumberInput';
import ImageUpload from 'components/custom/ImagePicker/ImageUpload';
import LocationPicker from 'components/Location/LocationPicker.js';
import WorkingHoursPicker from 'components/custom/WorkingHoursPicker/WorkingHoursPicker';
import { roseColor } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';

import { defaultReportTime, defaultWorkingHoursObj } from 'constants.js';
import { fetchTimezoneOptions } from 'actions/timezoneOptions';
//import moment from 'moment';
import moment from 'moment-timezone';
import zones from 'moment-timezone/data/meta/latest.json';
import defaultImage from "assets/img/default-avatar.png";

import { makeStyles } from "@material-ui/core/styles";
import validationFormStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormStyle';
import nonMutatingArray from 'utils/nonMutatingArray';
const useStyles = makeStyles(validationFormStyle);

const ProviderCreate = (props, itemRef) => {

    const classes = useStyles();
    const [timeZone, setTimeZone] = useState("");
    const [country, setCountry] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const dispatch = useDispatch();
    const timeZoneOptions = useSelector(state => state.options.timezone.tzOptions);
    const dispatchFetchTimezoneOptions = () => dispatch(fetchTimezoneOptions());

    const {allStates} = props;
    const orgWorkingHour = allStates?.organizationPayload?.workingHours ?? []; 

    useEffect(() => {
        let timeZone_ = moment.tz.guess();
        if (timeZone_ === 'Asia/Calcutta') {
            timeZone_ = 'Asia/Kolkata';
        }
        setTimeZone(timeZone_);
        dispatchFetchTimezoneOptions();
    }, [])

    useEffect(() => {
        if (timeZone) {
            let country_ = '';
            if (
                zones.zones[timeZone] &&
                zones.zones[timeZone]['countries'] &&
                zones.zones[timeZone]['countries'][0]
            ) {
                country_ = zones.zones[timeZone]['countries'][0];
            }
            setCountry(country_);
            if (country_) {
                let countryCode_ = getCountryCallingCode(country_);
                countryCode_ = countryCode_ ? "+" + countryCode_ : "";
                setCountryCode(countryCode_)
            }
        }
    }, [timeZone])

    const ProviderSchema = Yup.object().shape({
        email: Yup.string()
            .required('This field is required')
            .email('Email format is invalid'),
        givenName: Yup.string()
            .required('This field is required')
            .min(3, 'Name should be atleast 3 letters')
            .max(40, 'Too Long'),
        providerInformation: Yup.object({
            description: Yup.string()
                .min(5, 'Too short description')
                .max(500, 'Description must be below 500 characters'),
        }),
        /* telephone: Yup.string().required('Please enter a valid phone Number')*/
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            designation: '',
            familyName: '',
            givenName: '',
            telephone: '',
            registrationNumber: '',
            specialization: '',
            userStatus: 'FORCE_CHANGE_PASSWORD',
            provider: 'aws',
            userSub: '',
            userType: 'PROVIDER',
            providerInformation: {
                description: '',
                qualifications: '',
                tags: '',
                timeZoneId: 'Asia/Kolkata',
                isAdmin: false,
                image:null,
                workingHours: orgWorkingHour?.length ? nonMutatingArray(orgWorkingHour) : null,
            },
        },
        validationSchema: ProviderSchema,
        onSubmit: values => { },
        enableReinitialize: true
    });

    const {
        values,
        errors,
        status,
        isValid,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        setTouched,
        setFieldTouched,
        validateForm,
    } = formik;


    const wizardSubmit = async (allStates) => {
        setTouched({
            email: true,
            givenName: true,
            providerInformation:{
                description: true,
            }
        }, true);//touches all fields

        const errors = await validateForm();
        const errorFields = Object.keys(errors);
        let payload = formik.values;
        payload.designation = payload?.designation == "none" ? "" : payload.designation; 
        console.log("wizard Submit ProviderCreate payload >>", payload)
        //console.log("wizard Submit ProviderCreate validateForm >>", errors)

        const resp = errorFields?.length ? false : payload;
        return resp;
    }

    const handleProviderPicture = (e) => {
        console.log("handleImageChange", e);
        setFieldValue("providerInformation.image", e)
    }

    useImperativeHandle(itemRef, () => ({
        wizardSubmit: wizardSubmit
    }))

    const designations = [
        { value: "none", label: "No designation" },
        { value: "Mr", label: 'Mr' },
        { value: "Mrs", label: 'Mrs' },
        { value: "Ms", label: 'Ms' },
        { value: "Dr", label: 'Dr' },
    ];

    return (
        <>
            <GridItem xs={12} sm={12} md={12}>
                <form>
                    <Card>
                        {/* 
                        <CardHeader color="rose" text>
                            <CardText color="rose">
                                <h4 className={classes.cardTitle}>Create Provider</h4>
                            </CardText>
                        </CardHeader>
                         */}
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel
                                        className={`${classes.labelHorizontal}`}
                                    >
                                        Title
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <Select
                                            value={values.designation}
                                            name="designation"
                                            onChange={handleChange}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            inputProps={{
                                                name: "designation",
                                                id: "designation"
                                            }}
                                        >
                                            {designations.map((elem) => (
                                                <MenuItem
                                                    key={elem.label}
                                                    value={elem.value}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                >
                                                    {elem.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>First Name *</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <CustomInput
                                        id="givenName"
                                        name="givenName"
                                        inputProps={{
                                            onChange: handleChange,
                                            value: values.givenName
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                    {errors.givenName && touched.givenName ? (
                                        <div style={{ color: 'red' }}>{errors.givenName}</div>
                                    ) : null}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Last Name</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <CustomInput
                                        id="familyName"
                                        name="familyName"
                                        inputProps={{
                                            onChange: handleChange,
                                            value: values.familyName
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Email *</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <CustomInput
                                        id="email"
                                        name="email"
                                        inputProps={{
                                            onChange: handleChange,
                                            value: values.email
                                        }}
                                        formControlProps={{ fullWidth: true }}
                                    />
                                    {errors.email && touched.email ? (
                                        <div style={{ color: 'red' }}>{errors.email}</div>
                                    ) : null}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Make Admin</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <div style={{marginTop:28}}>
                                        <Switch
                                            checked={values.providerInformation.isAdmin}
                                            onChange={(e) =>
                                                setFieldValue(
                                                    'providerInformation.isAdmin',
                                                    e.target.checked
                                                )
                                            }
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            classes={{
                                                switchBase: classes.switchBase,
                                                checked: classes.switchChecked,
                                                thumb: classes.switchIcon,
                                                track: classes.switchBar,
                                            }}
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Telephone</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <div>
                                        <PhoneInput
                                            id="telephone"
                                            placeholder={"eg. " + countryCode + " xxxxxxxxxx"}
                                            defaultCountry={country}
                                            //onCountryChange={handleCountryChange}
                                            name="telephone"
                                            value={values.telephone}
                                            inputComponent={CustomPhoneNumberInput}
                                            onChange={e => setFieldValue('telephone', e)}
                                        />
                                    </div>
                                    {errors.telephone && touched.telephone ? (
                                        <div style={{ color: 'red' }}>{errors.telephone}</div>
                                    ) : null}
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Description</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <CustomInput
                                        id="providerInformation.description"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            multiline: true,
                                            value: values.providerInformation.description,
                                            onChange: handleChange,
                                            maxLength: 500,
                                        }}
                                    />
                                    {errors.providerInformation && touched.providerInformation && errors.providerInformation.description && touched.providerInformation.description ? (
                                        <div style={{ color: 'red' }}>{errors.providerInformation.description}</div>
                                    ) : null}
                                </GridItem>
                            </GridContainer>

                            {values.designation && values.designation.value === 'Dr' ?
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4}>
                                        <FormLabel className={classes.labelHorizontal}>Registration Number</FormLabel>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={6}>
                                        <CustomInput
                                            id="registrationNumber"
                                            name="registrationNumber"
                                            inputProps={{
                                                onChange: handleChange,
                                                value: values.registrationNumber
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer> : null}
                            {values.designation && values.designation.value === 'Dr' ?
                                <GridContainer>
                                    <GridItem xs={4} sm={4} md={4}>
                                        <FormLabel className={classes.labelHorizontal}>Specialization</FormLabel>
                                    </GridItem>
                                    <GridItem xs={8} sm={8} md={6}>
                                        <CustomInput
                                            id="specialization"
                                            name="specialization"
                                            inputProps={{
                                                onChange: handleChange,
                                                value: values.specialization
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                : null
                            }
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel
                                        className={`${classes.labelHorizontal}`}
                                    >
                                        Time Zone
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <Select
                                            name="providerInformation.timeZoneId"
                                            value={values.providerInformation.timeZoneId}
                                            onChange={handleChange}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            inputProps={{
                                                name: "providerInformation.timeZoneId",
                                                id: "providerInformation.timeZoneId"
                                            }}
                                        >
                                            {timeZoneOptions.map((elem) => (
                                                <MenuItem
                                                    key={elem.value}
                                                    value={elem.label}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                >
                                                    {elem.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>
                                        Working Hours
                                    </FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <GridContainer>
                                        <FormControl fullWidth className={classes.selectFormControl}>
                                            <WorkingHoursPicker
                                                workingHours={values.providerInformation.workingHours}
                                                initialValue={ orgWorkingHour?.length ? nonMutatingArray(orgWorkingHour) : null }
                                                onChange={(workingHours_) => setFieldValue("providerInformation.workingHours", [...workingHours_])}
                                            />
                                        </FormControl>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={4} sm={4} md={4}>
                                    <FormLabel className={classes.labelHorizontal}>Qualifications</FormLabel>
                                </GridItem>
                                <GridItem xs={8} sm={8} md={6}>
                                    <CustomInput
                                        id="providerInformation.qualifications"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            multiline: true,
                                            value: values.providerInformation.qualifications,
                                            onChange: handleChange
                                        }}
                                        placeholder="Empathy,Thick Skin,Flexibility,..."
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} md={12}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <ImageUpload
                                            currentPictureUrl={defaultImage}
                                            aspectRatio={1}
                                            localImageName={'imageObject'}
                                            onPictureChange={handleProviderPicture}
                                            keyNamePrefix="provider-image"
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                        </CardFooter>
                    </Card>
                </form>
            </GridItem>
        </>
    )

}
export default forwardRef(ProviderCreate);